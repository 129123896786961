import { Component, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors,FormGroup, FormControl, Validators ,ValidatorFn } from '@angular/forms';
import { commonclass } from '../../../../common/common';
import { ApiServicesService } from '../../../../services/api-services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.scss']
})
export class SubscriptionFormComponent implements OnInit {

  disabled : boolean = false
  isDisabled : boolean= false;
  pullModelMsg: string = "Data pull initiated.";
  subcriptionForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required,this.passwordValidator()]),
    api_endpoint: new FormControl('',[Validators.required]),
    login_api_endpoint: new FormControl('',[Validators.required]),
    login_client_id: new FormControl('',[Validators.required]),
  });
  constructor(private apis : ApiServicesService , private msgs : commonclass) { }

  ngOnInit(): void {
    this.getSubscriptionDetails();
  }
  /**
   * Get subscription form details
   */
  getSubscriptionDetails(){
    this.apis
    .sendGetRequest(
      'subscritpion-info',
    )
    .subscribe((result) => {
      this.subcriptionForm.patchValue({
        username : result?.username,
        password : result?.password,
        api_endpoint : result?.api_endpoint,
        login_api_endpoint: result?.login_endpoint,
        login_client_id: result?.login_client_id
      });
    });
  }
  /**
   * save subscription form
   */
  saveForm(){
    let payload = {
      username : this.subcriptionForm.value.username,
      password : this.subcriptionForm.value.password,
      api_endpoint : this.subcriptionForm.value.api_endpoint,
      login_endpoint: this.subcriptionForm.value.login_api_endpoint,
      login_client_id: this.subcriptionForm.value.login_client_id
    }
    this.apis
    .sendPostRequest(
      'subscritpion-info',
      payload
    )
    .subscribe((result) => {
      console.log(result)
      this.subcriptionForm.patchValue({
        username :  this.subcriptionForm.value.username,
        password :  this.subcriptionForm.value.password,
        api_endpoint : this.subcriptionForm.value.api_endpoint,
        login_api_endpoint: this.subcriptionForm.value.login_api_endpoint,
        login_client_id: this.subcriptionForm.value.login_client_id
      });
      this.msgs.showNotification(result.message)
    });
  }
  /**
   * Get form errors
   */
  get formControls() {
      return this.subcriptionForm.controls;
  }
  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      //Pattern for password
      const pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-@$!%*#?&])[A-Za-z\d-@$!%*#?&]{8,}$/;
      const isValid = pwdPattern.test(value);
      return !isValid ? { password: 'invalid' } : null;
    };
  }

  /**
   * pull request here
   */
   pullRequest(){
    this.isDisabled= true;
    setTimeout(()=>{
      this.isDisabled= false;
    },10000);
    this.apis
    .sendPullModelRequest(
      environment.pull_model_url,
      null
    ).subscribe((result) => {});
    this.msgs.showNotification(this.pullModelMsg)
   }
}
