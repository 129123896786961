import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-key-management',
  templateUrl: './key-management.component.html',
  styleUrls: ['./key-management.component.scss']
})
export class KeyManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
