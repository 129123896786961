import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AwsKeyManagementService } from 'src/app/services/aws-key-management.service';

@Component({
  selector: 'app-delete-key',
  templateUrl: './delete-key.component.html',
  styleUrls: ['./delete-key.component.scss'],
})

/**
 * DeleteKeyComponent is used to delete key from RPM cloud.
 * @author  Asif Basheer
 * @since   2022-09-12
 */
export class DeleteKeyComponent implements OnInit {
  publicKey:string;
  keyAlias:string;
  constructor(
    private dialogRef: MatDialogRef<DeleteKeyComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: any,
    public awsKeyMgmtService: AwsKeyManagementService,
  ) {
    this.publicKey = data.public_key;
    this.keyAlias = data.key_alias;
  }

  ngOnInit(): void {}

  /**
   * Function to close the dialog.
   */
  close() {
    this.dialogRef.close();
  }

   /**
   * Function to delete Key.
   */
  deleteKey() {
    console.log(this.publicKey);
    this.awsKeyMgmtService.deletePublicKey(this.publicKey).subscribe(
      (response: any) => {
        this.dialogRef.close({
          proceed: true,
          response: response
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
