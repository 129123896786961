<div class="homecover">
    <div class="header">
        <app-header></app-header>
    </div>
    <div id="appcontainer" class="appcontainer">
        <div class="sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="datacover">
            <app-datacontainer></app-datacontainer>
        </div>
    </div>
    <div>
        <footer style="text-align: center;  position: absolute;left: 0; bottom: 1rem; width: 100%;">
            Version {{version_number}}
        </footer>
    </div>
</div>
