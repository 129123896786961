import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-activity-tracker-dialogue',
  templateUrl: './activity-tracker-dialogue.component.html',
  styleUrls: ['./activity-tracker-dialogue.component.scss']
})
export class ActivityTrackerDialogueComponent implements OnInit {
  activityTrackerReadings = [];
  readyType: string = "Pulse Reading";
  makeModel: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any
  ) { 
    const telObj= Object.keys(data?.telemetry_data?.readings[0]);
    this.readyType=telObj.includes('pulseRate') ? "Pulse Reading" : "Steps Reading";
    this.activityTrackerReadings=data?.telemetry_data?.readings;
    this.makeModel= data?.make+" "+data?.device_type;
  }

  ngOnInit(): void {
  }

}
