<h2 matDialogTitle>Delete Key</h2>
<div class="diaclose-icon" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</div>
<mat-dialog-content>
  <div>Are you sure want to delete <b>{{keyAlias}}</b> ?</div>
</mat-dialog-content>
<mat-dialog-actions class="app-dialog-actions">
  <button (click)="deleteKey()" class="btn btn-primary btn-sm">
    Delete
  </button>
  <button mat-button matDialogClose class="btn btn-outline-secondary btn-sm">
    Cancel
  </button>
</mat-dialog-actions>
