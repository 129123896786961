<h2 matDialogTitle class="modal-title">{{ readyType }} - {{ makeModel }}</h2>
<div class="diaclose-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
</div>
<div class="grid-wrap telemetry-details-table">
    <p-table [value]="activityTrackerReadings">
        <ng-template pTemplate="header">
            <tr>
                <th>Telemetry Data</th>
                <th>Reading Taken</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reading>
            <tr>
                <td>{{reading?.steps || reading?.pulseRate}}</td>
                <td>{{ reading?.timestamp | formatDate  }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
