// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  logo_url: '../assets/images/logo.svg',
  api_url : 'https://mjkd4k4kpl.execute-api.ap-south-1.amazonaws.com/development/v1/demoapp/',
  pull_model_url : 'https://mjkd4k4kpl.execute-api.ap-south-1.amazonaws.com/development/v1/demoapp/telemetry/pull',
  pull_model_token : 'RirkLJ3Po86vY35JaFPYs2IaU8FLUKHc8zSwCrpy',
  telemetry_endpoint: 'https://api-rpm-democloud-dev.ches.korewireless.com/v2/telemetry/publish',
  appVersion: require('../../package.json').version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
