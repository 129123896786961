<div class="appsidebar">
    <div class="menuwrapper">
        <ul>
            <li id="patient-information" routerLink="patient-information" routerLinkActive="active">
                <a>
                    <i class="fas fa-user"></i>
                    <span class="menuname">Patient Information</span>
                    <span class="ball"></span>
                </a>
            </li>
            <li id="key-management" routerLink="key-management" routerLinkActive="active">
                <a>
                    <i class="pi pi-key font-600 keyiconleft"></i>
                    <span class="menuname">Key Management</span>
                    <span class="ball"></span>
                </a>
            </li>
            <li routerLink="configuration" routerLinkActive="active">
                <a>
                    <i class="fas fa-cog"></i>
                    <span class="menuname">Configuration</span>
                    <span class="ball"></span>
                </a>
            </li>
        </ul>
    </div>
</div>